import { jenish,
    pranay,
    mayanak,
    rahul,
    kunal,
    React,
    sushmita,
    lekhnath,
    swagata,
    akruti,
    sayan,
    riya,
    gouda,
    astha,
    prabhu,
    panda,
    anupkumar,
    zahir,
    Poonam,
    SidhiGRamer,
    ummedsingh,
    kumarisunita,



    } from "../../../../assets";

export const strp_phd = [
    {
        "Name":"SUSHMITA AGARWAL",
        "dis":" working with Dr. Amit Shukla, from IITI did her fellowship from Gran Sasso Science Institute, L’Aquila, Italy under Prof. Marica Branchesi",
        img : sushmita,
    },
    {
        "Name":"RAHUL KUMAR YADAV",
        "dis":"working with Dr. Dipak Kumar Roy, from IITI did his fellowship from Universite de Rennis 1, France under Dr. Yann Sarazin",
        img : rahul,
    },
    {
        "Name":"MAYANK DUBEY",
        "dis":"working with Prof. Shaibal mukherjee from IITI did his fellowship from Shinshu University, Japan under Dr. Myo Than Htay",
        img : mayanak,
    },
    {
        "Name":"KUNAL GUPTA",
        "dis":"working with Prof. Neelima Satyam D from IITI did his fellowship from University of Florence, Italy under Dr. Samuele Segoni",
        img : kunal,
    },
    {
        "Name":"LEKHNATH SHARMA",
        "dis":"working with Dr. Chelvam Venkatesh from IITI did his fellowship from Purdue University, Unite States of America under Dr. Samuele Segoni",
        img : lekhnath,
    },
]

export const strp_ug_pg11 = [
    {
        "Name":"Ms. Swagata lakshmi Dhali",
        "dis":"  from BSBE department at IITI will be persuing her fellowship from Institute of Sanitary Engineering and waste, management, Leibniz University Hannover, Germany",
        img:swagata,
    },
    {
        "Name":"Mr. Sayan Doloi",
        "dis":"  from Mechanical Engineering department at IITI will be persuing his fellowship at department of Material and process sciences for composite materials,IMT Nord Europe, Douai Campus, France",
        img:sayan,
    },
    {
        "Name":"Ms. Akruti taori",
        "dis":"  from Electrical Engineering department at IITI will be persuing her fellowship at Department of Electrical Engineering, University West Sweden (MoU)",
        img:akruti,
        
    },
    {
        "Name":"Ms. Riya Sehrawat",
        "dis":"  from Chemistry department at IITI will be persuing her fellowship at Department of Chemistry,Leibniz Institute for Catalysis, University of Rostock",
        img:riya,
        
    },
    {
        "Name":"Ms. Kumari sunita",
        "dis":" from Mechanical Engineering department at IITI will be persuing her fellowship at Department of Mechanical Engineering , Gustave Eiffel University, paris, france",
        img:kumarisunita,
        
    },
]

export const strp_ug_pg = [
    {
        "Name":"JENISH SHAH",
        "dis":" from IITI did his semester exchange from IMT Atlantique, France in Architecture and Engineering for the Internet of Things",
        img : jenish,
    },
    {
        "Name":"NAGILLA PRANAY REDDY",
        "dis":"from IITI did his semester exchange from Nagaoka University of Technology, Japan in Master’s Program in Engineering, Mechanical Engineering",
        img : pranay,
    },
]
export const strp_phd11 = [
    {
        "Name":"Ms. Monika Yadav",
        "dis":"from department of Humanities and Social Sciences mentored by Dr. Pritee Sharma will be persuing her fellowship under Dr. Liah Greenfield at Department of Sociology, Boston University, USA",
        
    },
    {
        "Name":"Ms. Poonam Suresh Deshmukh",
        "dis":"from department of Mechanical Engineering mentored by Dr. DanSathiaraj will be persuing her fellowship under Dr. ManojGupta at Mechanical Engineering, NationalUniversity of Singapore",
        img:Poonam,
        
    },
    {
        "Name":"Ms. SidhiG Ramer",
        "dis":"from department of Electrical Engineering mentored by Dr. Vipul Singh will be persuing her fellowship under Dr. Shyam S Pandey at Department  of Biological functions and systems, Kyushu Institute of Technology, Japan",
        img : SidhiGRamer,
    },
    {
        "Name":"Mr. Zahir Abbas",
        "dis":"from department of Chemistry mentored by Dr. Shail‹h.M. Mobin will be persuing his fellowship under Dr Sang-Jae Kim as Faculty of AppJied Energy System,Jeju National University, South Korea ",
        img:zahir,
        
    },
    {
        "Name":"Mr. Anup Kumar Gupta",
        "dis":"from department of Computer Engineering mentored by Dr. Punee Gupta will be persuing his fellowship under Dr.Aniket Bera at Department of Computer Science,Purdue University, USA",
        img:anupkumar,
        
    },
    {
        "Name":"Ms. Astha Sharma",
        "dis":"from department of Civil Engineering mentored by Dr. Sandeep Chaudhary will be persuing her fellowship under Dr.Thanongsak at Department  of Civil Engineering,Walailak University, Thailand",
        img:astha,
        
    },
    {
        "Name":"Mr. Ummed Singh",
        "dis":"from department of Mechanical Engineering mentored byDr. Ashish Kumar Rajak will be persuing his fellowship under Dr. Taeseon Lee at Department  of Mechanical Engineering, Incheon National University, South Korea",
        img : ummedsingh,
        
    },
    {
        "Name":"Mr. Dhivya Prabhu K",
        "dis":"from department of Mathematics mentored by Dr Sanjeev Singh and Dr Antony Vijesh will be persuing his fellowship under  Dr.Arpad Baricz at Department  of Business Administration, Babes Bolyai University, Romania",
        img:prabhu,
        
    },
    {
        "Name":"Mr. Sugata Panda",
        "dis":"from department of Civil Engineering  mentored by Dr. Mayur Shirish Jain will be persuing her fellowship under Dr. Prasad Kaparaju  at Department  of Civil and Environmental  Engineering, GriffithUniversity,Australia",
        img:panda,
        
    },
    {
        "Name":"Mr. Akhila Gouda",
        "dis":"from department mentored by Dr. Saptarshi Ghosh will be persuing her fellowship under Dr. Denys Nikolayev at IERT Laboratory of CNRS, University of Rennes 1, France",
        img:gouda,
        
    },
    
]





export const faqData_outphd = [
    {
        question: 'What documents must be submitted during the application process?',
        answer:() => (
            <>
                <p>
                The following documents may be required at the time of application submission as per your choice of program:  
                </p>
                <ul>
                    <li>Curriculum Vitae of Applicant</li>
                    <li>Curriculum Vitae of Faculty Supervisor from IIT Indore</li>
                    <li>Curriculum Vitae of Faculty Supervisor from Host Institute</li>
                    <li>Copy of Passport</li>
                    <li>Academic Degree/ Marksheet</li>
                    <li>Academic Degree/ Marksheet (Last Semester)</li>
                    <li>Letter of Consent from the Applicant's faculty supervisor of IIT Indore</li>
                    <li>Letter of Invitation from the Applicant's faculty supervisor of Host Institute</li>
                    <li>Research Proposal</li>
                    <li>Passport Size Photograph</li>
                    <li>TOEFL/IELTS/Recommendation from home Institute/Other documents (For International Students Only)</li>
                </ul>
            </>
        )   
    },
    {
        question: 'What are the visa requirements?',
        answer:() => (
            <>
                <p>
                    To apply for a visa, please visit the official website of the Bureau of Immigration (BOI), Government of India, <a href="https://boi.gov.in/content/apply-visa-online"> https://boi.gov.in/content/apply-visa-online </a> or <a  href="https://indianvisaonline.gov.in/visa/.">https://indianvisaonline.gov.in/visa/.</a>
                </p>
                <p>
                    You will need to submit an acceptance letter (written by the faculty supervisor of your host institute) or a consent letter (written by your faculty supervisor of your home institute) along with a No Objection Certificate (NOC) from the home institute at the time of your visa interview. You may also need to produce your last three months’ salary slips if you are a PhD student (the same can be requested by the finance section).
                </p>
            </>
        )
    },
    {
        question: 'Is there a particular format for Research Proposals that can be used?',
        answer: 'The answer is yes, the format can be found on the official website of International Relations (IR) at IIT Indore, under the Inbound and Outbound sections.'
    },
    {
        question:'Is there a foreign leave application to be completed?',
        answer:'The answer is yes. A signed ex-India leave application is to be submitted based on the approved NOC application.'
    },
    {
        question:'How to receive the travel grant in students’ accounts?',
        answer:'The advance form must be completed and settled upon the conclusion of the visit.'
    },
    {
        question:'Following the completion of the visit, how to settle the travel grant received?',
        answer:' All invoices, boarding passes, and tickets need to be submitted in the originals, along with the form for the advance settlement. All documents must be submitted to the IR Office for further processing.'
    },
    {
        question:'Which currency should be indicated in the advance settlement form?',
        answer:'All expenditure shall be denominated in Indian currency from the date of departure from India.'
    },
    {
        question:'Is it mandatory to submit a report upon completion of the program?',
        answer:'The answer is yes. A detailed report needs to be submitted after the completion of the program. Inbound students will receive a completion certificate based on the submission of the report.'
    },
    {
        question:'Is the Office of International Relations, IIT Indore, accountable for student visa applications?',
        answer:'The answer is No. The IR office is not responsible for a student’s visa application.'
    },
    {
        question:' In the event that the student does not successfully commence the program at the host Institute/University, what is the next step?',
        answer:' If a student fails to start the program, they must return the advance (amount) immediately to the institute.'
    }
] 

export const faqData_outUG_PG = [
    {
        question: 'What documents must be submitted during the application process?',
        answer:() => (
            <>
                <p>
                The following documents may be required at the time of application submission as per your choice of program:  
                </p>
                <ul>
                    <li>Curriculum Vitae of Applicant</li>
                    <li>Curriculum Vitae of Faculty Supervisor from IIT Indore</li>
                    <li>Curriculum Vitae of Faculty Supervisor from Host Institute</li>
                    <li>Copy of Passport</li>
                    <li>Academic Degree/ Marksheet</li>
                    <li>Academic Degree/ Marksheet (Last Semester)</li>
                    <li>Letter of Consent from the Applicant's faculty supervisor of IIT Indore</li>
                    <li>Letter of Invitation from the Applicant's faculty supervisor of Host Institute</li>
                    <li>Research Proposal</li>
                    <li>Passport Size Photograph</li>
                    <li>TOEFL/IELTS/Recommendation from home Institute/Other documents (For International Students Only)</li>
                </ul>
            </>
        )   
    },
    {
        question: 'What are the visa requirements?',
        answer:() => (
            <>
                <p>
                    To apply for a visa, please visit the official website of the Bureau of Immigration (BOI), Government of India, <a href="https://boi.gov.in/content/apply-visa-online"> https://boi.gov.in/content/apply-visa-online </a> or <a  href="https://indianvisaonline.gov.in/visa/.">https://indianvisaonline.gov.in/visa/.</a>
                </p>
                <p>
                    You will need to submit an acceptance letter (written by the faculty supervisor of your host institute) or a consent letter (written by your faculty supervisor of your home institute) along with a No Objection Certificate (NOC) from the home institute at the time of your visa interview. You may also need to produce your last three months’ salary slips if you are a PhD student (the same can be requested by the finance section).
                </p>
            </>
        )
    },
    {
        question: 'Is there a particular format for Research Proposals that can be used?',
        answer: 'The answer is yes, the format can be found on the official website of International Relations (IR) at IIT Indore, under the Inbound and Outbound sections.'
    },
    {
        question:'Is there a foreign leave application to be completed?',
        answer:'The answer is yes. A signed ex-India leave application is to be submitted based on the approved NOC application.'
    },
    {
        question:'How to arrange course mapping for UG/ PG students?',
        answer:'Students need to arrange course mapping on their own by coordinating with the respective department head, the academic office of IIT Indore, and partner university where they want to enrol. This is the only way to get NOC for the student from home institute.'
    },
    {
        question:'How to receive the travel grant in students\' accounts?',
        answer:'The advance form must be completed and settled upon the conclusion of the visit.'
    },
    {
        question:'Following the completion of the visit, how to settle the travel grant received?',
        answer:' All invoices, boarding passes, and tickets need to be submitted in the originals, along with the form for the advance settlement. All documents must be submitted to the IR Office for further processing.'
    },
    {
        question:'Which currency should be indicated in the advance settlement form?',
        answer:'All expenditure shall be denominated in Indian currency from the date of departure from India.'
    },
    {
        question:'Is it mandatory to submit a report upon completion of the program?',
        answer:'The answer is yes. A detailed report needs to be submitted after the completion of the program. Inbound students will receive a completion certificate based on the submission of the report.'
    },
    {
        question:'Is the Office of International Relations, IIT Indore, accountable for student visa applications?',
        answer:'The answer is No. The IR office is not responsible for a student\'s visa application.'
    },
    {
        question:' In the event that the student does not successfully commence the program at the host Institute/University, what is the next step?',
        answer:' If a student fails to start the program, they must return the advance (amount) immediately to the institute.'
    }
] 