export const data = [
  {
    key: "1",
    Ranking: 4,
    Heading: "THE World University Ranking 2022 India",
  },
  {
    key: "2",
    Ranking: 14,
    Heading: "NIRF 2023 Engineering",
  },
  {
    key: "3",
    Ranking: 28,
    Heading: "NIRF 2023 Overall",
  },
  // {
  //   key: "4",
  //   Ranking: 9,
  //   Heading: "QS World University Ranking 2023 India"
  // },
  {
    key: "5",
    Ranking: 87,
    Heading: "THE World University Ranking 2022 Asia",
  },
  {
    key: "6",
    Ranking: 84,
    Heading: "THE Emerging Economies 2022",
  },
  {
    key: "7",
    Ranking: 86,
    Heading: "THE Young University Ranking 2022",
  },
  {
    key: "8",
    Ranking: 396,
    Heading: "QS World University Ranking 2023 World",
  },
];
